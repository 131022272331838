import { Instagram } from "@mui/icons-material";
import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const SocialInvites = (props) => {
    const { t } = useTranslation()
    const [copied, setCopied] = useState(false);
    const renderPopover = (content) => (
        <Popover>
            <Popover.Body>{`${content}`}</Popover.Body>
        </Popover>
    );
    const copyToClipboard = async (text) => {
        console.log(text,"texttext");
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Clipboard copy failed:", error);
            }
        } else {
            // Clipboard API is not supported, provide a fallback method
            try {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Fallback clipboard copy failed:", error);
            }
        }
    };
    const facebookData = props.data && props.data.filter(item => item.type === "social_facebook") || [];
    const instagramData = props.data && props.data.filter(item => item.type === "social_instagram") || [];
    const twitterData = props.data && props.data.filter(item => item.type === "social_twitter") || [];
console.log(facebookData,"text");
    return (
        <div className="tab-pane fade active show" id="nav-banner" role="tabpanel" aria-labelledby="nav-social-tab">
            {props.isLoading ? (
                <>
                    <h4 className="heading_style_1 mt-2"><Skeleton /></h4>
                    <div className="d-flex gap-2">
                        <Skeleton />
                    </div>
                </>

            ) : (
                <>
                    {facebookData.length > 0 &&
                        <>
                            <h4 className="heading_style_1 mt-2">Facebook</h4>
                            {facebookData.map((item, index) => (
                                <>
                                    <div key={index} className="d-flex gap-2">
                                        {console.log(item)}
                                        <div dangerouslySetInnerHTML={{ __html: item.content }} className="form-control" disabled></div>
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={renderPopover(copied ? "Copied" : "Copy")}>
                                            <button className="btn btn-primary" onClick={() => copyToClipboard(item.content)}>Copy</button>
                                        </OverlayTrigger>
                                    </div>
                                    <br />
                                </>
                            ))}
                        </>
                    }
                    {instagramData.length > 0 &&
                        <>
                            <h4 className="heading_style_1 mt-2">Instagram</h4>
                            {instagramData.map((item, index) => (
                                <>
                                    <div key={index} className="d-flex gap-2">
                                        <div dangerouslySetInnerHTML={{ __html: item.content }} className="form-control" disabled></div>
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={renderPopover(copied ? "Copied" : "Copy")}>
                                            <button className="btn btn-primary" onClick={() => copyToClipboard(item.content)}>Copy</button>
                                        </OverlayTrigger>
                                    </div>
                                    <br />
                                </>

                            ))}

                        </>
                    }
                    {twitterData.length > 0 &&
                        <>
                            <h4 className="heading_style_1 mt-2">Twitter</h4>
                            {twitterData.map((item, index) => (
                                <>
                                    <div key={index} className="d-flex gap-2">
                                        <div dangerouslySetInnerHTML={{ __html: item.content }} className="form-control" disabled></div>
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={renderPopover(copied ? "Copied" : "Copy")}>
                                            <button className="btn btn-primary" onClick={() => copyToClipboard(item.content)}>Copy</button>
                                        </OverlayTrigger>
                                    </div>
                                    <br />
                                </>
                            ))}
                        </>
                    }
                </>
            )}
        </div>
    )
};

export default SocialInvites;