import DOMPurify from "dompurify";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { ReactMultiEmail, isEmail } from 'react-multi-email';

const EmailInvites = (props) => {
    // define
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [emails, setEmails] = useState([]);

    const handleOpenModal = (item) => { 
        setCurrentItem(item);
        setIsModalOpen(true); 
    };
    const handleCloseModal = () => {
        setCurrentItem(null);
        setIsModalOpen(false);
    };
    return (
        <>
            {props.isLoading ? (
                <div className="tab-pane fade active show" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                    <h4 className="heading_style_1">
                        <Skeleton width="15%" />
                    </h4>
                    <div className="emailInviteRow">
                        <div className="emailInvite_img">
                            <Skeleton width="100%" height="100%" />
                        </div>
                        <div className="emailInviteText">
                            <h4><Skeleton width="25%" /></h4>
                            <p>
                                <Skeleton width={500} height={60} />
                            </p>
                            <button type="submit" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mailinvite" disabled>
                                Preview
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="tab-pane fade active show" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                    <h4 className="heading_style_1">Email Invites</h4>
                    {props.data.map((item, key) => (
                        <div key={key} className="emailInviteRow">
                            <div className="emailInvite_img"></div>
                            <div className="emailInviteText">
                                <h4>{item.subject}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(item.content),
                                    }}
                                />
                                {/* <button type="submit" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mailinvite" onClick={() => handleOpenModal(item)}>
                                    Preview
                                </button> */}
                            </div>
                        </div>
                    ))}


                    {/* <h4 className="heading_style_1 mt-5">Invites History</h4>
                    <div className="emailInviteRow">
                        <div className="emailInvite_img"></div>
                        <div className="emailInviteText">
                            <h4>This is some placeholder content</h4>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                            <p>To: mail@mail.com</p>
                        </div>
                    </div> */}
                </div>
            )}
            {/* modal pop-up*/}
            <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>EMAIL INVITE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label for="recipient-name" className="col-form-label">
                                To:*
                            </label>
                            {/* <input type="text" className="form-control" id="recipient-name" /> */}
                            <ReactMultiEmail className="" emails={emails} onChange={(data) => setEmails(data)} autoFocus 
                            getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                        </div>
                        <div className="mb-3">
                            <p className="mb-0">"You can enter multiple email id's separated by comma..."</p>
                            <label for="message-text" className="col-form-label">
                                Subject:*
                            </label>
                            <input className="form-control" id="message-text" value={currentItem?.subject} disabled/>
                        </div>
                        <div className="mb-3">
                            <label for="message-text" className="col-form-label">
                                Message:*
                            </label>
                            <textarea className="form-control" id="message-text" value={currentItem?.content} disabled/>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary">Invite</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default EmailInvites;