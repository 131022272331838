import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PromotionNavigation from "../../components/tools/promotion-tools/PromotionNavigation";
import { ApiHook } from "../../hooks/apiHook";
import Skeleton from "react-loading-skeleton";

const PromotionTools = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState("email")
    const promotionDetails = ApiHook.CallGetPrmotionDetails(tab);
    // const promotionDetails = false;
    console.log(promotionDetails?.data?.data,"promotionDetails?.data?.data");
    return (
        <>
            <div className="page_head_top">{t("promotion-tools")}</div>
            <PromotionNavigation tab={tab} setTab={setTab} data={promotionDetails?.data?.data || []} isLoading={promotionDetails.isFetching} /> :
        </>
    )
};

export default PromotionTools;