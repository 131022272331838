import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import style from "./payment.module.scss";
import Alert from "react-bootstrap/Alert";
// import ReplicaRegService from "../../../service/Auth/Replicareg";
// import AlertMessage from "../common/AlertMessage";
// import Spinner from "../../shared/Spinner";
import { ApiHook } from "../../hooks/apiHook";

const Checkout = (props) => {

  const [processing, setProcessing] = useState(false);
  const [stripeError, setStripeError] = useState();
  const [stripesucess, setStripesucess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const stripeCheck = ApiHook.StripeCheck();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let res;
    setProcessing(true);
    setStripeError("");
    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: props?.stripeData?.email,
        name: props?.stripeData?.name,
        phone: props?.stripeData?.phone,
      },
    });
    if (error) {
      console.log(" inside stripe error ");
      setStripeError(error.message);
      setProcessing(false);
      return;
    }
    console.log(" === after stripe === ", localStorage.getItem("user"));
    const username = localStorage.getItem("user")
    let Data = {
      intentId: paymentMethod?.id,
      data: {
        email: props?.stripeData?.email,
        name: props?.stripeData?.name,
        desc: props?.stripeData?.desc,
        amount: props.price,
        productId: props?.stripeData?.productId,
      },
      username,
      userAction: props?.stripeData?.userAction,
    }

    stripeCheck.mutateAsync(Data, {
      onSuccess: (res) => {
        console.log(res.data);
        if (res.data.status) {
          setProcessing(false);
          event.target.querySelector('button[type="submit"]').disabled = true;
          if (res.data.data.requireAction) {
            const { error, paymentIntent } =  stripe.confirmCardPayment(
              res.data.data.clientSecret,
              {
                payment_method: {
                  card: cardElement,
                },
              }
            );
            console.log(paymentIntent);
            if (error) {
              console.log(error);
              setStripeError(error.message);
            } else {
              if (paymentIntent.status === "succeeded") {
                setStripesucess(true);
                if (
                  window.location.pathname.includes("/register") ||
                  window.location.pathname.includes("/replica_register")
                ) {
                  props.submit(res.data.data, "", "payment");
                } else {
                  props.submit(res.data.data, "");
                }
              } else {
                console.log(paymentIntent);
                setStripeError("Payment failed");
              }
            }
            setProcessing(false);
          } else {
            setStripesucess(true);
            if (
              window.location.pathname.includes("/register") ||
              window.location.pathname.includes("/replica-register")
            ) {
              // props.setValue(res.data.data, "payment")
              const paymentId = Number(props.contact.paymentType) ,data=res.data.data
              props.handleSubmit(paymentId,data);
            } 
          }
        } else {
          setProcessing(false);
          setStripeError(
            res?.error?.description
              ? res?.error?.description
              : "Something went wrong.."
          );

          event.target.querySelector('button[type="submit"]').disabled = false;
        }


      }
    });
  }
  return (
    <>
      <div >
        {stripeError && <Alert variant="danger">{stripeError}</Alert>}
        {/* {stripesucess && (
          <AlertMessage
            message={"Payment Completed"}
            dismiss={dissmissNotify}
            type={"success"}
            header={"Success"}
            show={true}
          />
        )} */}
      </div>
      {props?.loader || !props?.stripeData?.status ? (
        <div> Loading Stripe ... </div>
      ) : (
        <>
          <form onSubmit={handleSubmit} id="payment-form" style={{ padding: "10px", width: "80%", margin: "25px" }}>
            <div
              id="card-element"
              style={{ padding: "10px", width: "100%", margin: "16px" }}
            >
              <CardElement
                options={{
                  base: {
                    color: "#32325d",
                    fontFamily: "Arial, sans-serif",
                    fontSmoothing: "antialiased",
                    fontSize: "20px",
                    "::placeholder": {
                      color: "#32325d",
                    },
                  },
                  invalid: {
                    fontFamily: "Arial, sans-serif",
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                }}
              />
              <button
                id="submit-new"
                type="submit"
                disabled={!stripe || processing}
                style={{
                  backgroundColor: "rgb(118, 60, 176)",
                  borderRadius: "10px",
                  color: "white",
                  marginTop: "30px",
                  fontWeight: "bold",
                  width: "150px",
                  padding: "10px"
                }}
              >
                {
                  <>
                    {processing ? (
                      <div style={{ width: "40px" }}>Processing...</div>
                    ) : (
                      <span id="button-text">
                        <i
                          // className="fa-solid fa-circle-notch fa-spin"
                          style={{ width: "40px", color: "#ffffff", }}
                        ></i>{" "}
                        Pay now
                      </span>
                    )}
                  </>
                }
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};
export default Checkout;
