import React, { useState, useRef } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import TableMain from "../../components/Common/table/TableMain";
import LabelledButton from "../../components/Common/buttons/LabelledButton";
import { useReactToPrint } from "react-to-print";
import { exportToCSV, exportToExcel } from "../../utils/tableExports";
import { PrintView } from "../../components/Common/PrinView";
import { getLastPage } from "../../utils/getLastPage";

const Customers = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const tableRef = useRef();
  //--------------------------------- API --------------------------------
  const customerDetails = ApiHook.CallCustomerDetails(
    currentPage,
    itemsPerPage
  );

  const headers = ["SID", "Name", "Email", "Date", "Payment", "Payment Type"];

  const lastPage = getLastPage(itemsPerPage, customerDetails?.data?.page);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <>
      <div className="page_head_top">{t("Customers")}</div>
      <>
        <div className="filter_Section">
          <div className="row justify-content-between">
            <div className="text-end mob_filter_right">
              <LabelledButton
                className="fa fa-file-excel"
                text=" Excel"
                click={() =>
                  exportToExcel(customerDetails?.data?.data, headers, "customer")
                }
              />
              <LabelledButton
                className="fa fa-file-text"
                text=" CSV"
                click={() =>
                  exportToCSV(customerDetails?.data?.data, headers, "customer")
                }
              />
              <LabelledButton
                className="fa fa-print"
                text=" Print"
                click={handlePrint}
              />
            </div>
            <div style={{ display: "none", padding: "10px" }}>
              <PrintView
                ref={tableRef}
                data={customerDetails?.data?.data ?? []}
                headers={headers}
                type={"customer"}
              />
            </div>
          </div>
        </div>
      </>
      <div className="table-responsive min-hieght-table">
        <TableMain
          headers={headers}
          data={customerDetails?.data?.data ?? []}
          startPage={1}
          currentPage={currentPage}
          totalPages={lastPage}
          type={"customer"}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default Customers;
