import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout.jsx";
import { ApiHook } from "../../hooks/apiHook";

const Stripe = (props) => {

  console.log("========================= inside ===================");
  console.log(props);

  let stripePromise = "";
  const [clientSecret, setClientSecret] = useState("");

  const createPayment = ApiHook.StripePayment(setClientSecret, props?.paymentId);
  console.log(props);
  const [stripeData, setstripeData] = useState({
    status: true,
    email: props.contact?.email ?? "",
    name: props.contact?.first_name ?? "",
    phone: props.contact?.mobile ?? "",
    desc: `${window.location.pathname.includes("/replica_register")
      ? "Replica Registration"
      : "Registration"
      }for ${props.contact?.email}`,
    userAction: window.location.pathname.includes("/replica_register")
      ? "replica_register"
      : "register",
    username: props.contact?.username ?? "",
    productId: props.contact?.product?.id ?? ""
  });

  if (clientSecret != "") {
    stripePromise = loadStripe(clientSecret);
  }
  return (
    <div className="app">
      {clientSecret != "" && stripePromise && stripeData.status && (
        <Elements stripe={stripePromise}>
          <Checkout
            {...props}
            stripeData={stripeData}
            clientSecret
          />
        </Elements>
      )}
    </div>
  );
}
export default Stripe;
