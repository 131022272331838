import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TableContent from "./table/TableContent";

export const PrintView = React.forwardRef((props, ref) => {

    const { t } = useTranslation();
    const companyProfile = useSelector(
        (state) => state?.dashboard?.appLayout?.companyProfile
    );

    return (
        <div className="purchase_invoice" ref={ref}>
            <button className="print-button" style={{ backgroundColor: 'white' }} >
                <i className="fa fa-angle-left" style={{ fontSize: "1.3em" }}></i>
            </button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfile?.logo ?? "/images/logo_user.png"} alt="" />
                </div>
                <div >
                    <p className="font-weight-bold mb-1">
                        {`${companyProfile?.name}`}
                    </p>
                    <p className="font-weight-bold mb-1">
                        {`${companyProfile?.address}`}
                    </p>
                    <p className="text-muted">
                        {`${t("date")}: ${new Date().toLocaleDateString('en-GB')}`}
                    </p>
                </div>
            </div>
            <hr className="my-1" />
            <div className="row p-1">
                <TableContent
                    print={true}
                    data={props.data}
                    headers={props.headers}
                    type={props.type}
                />
            </div>
        </div>
    );
});
