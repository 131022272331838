import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const BannerInvites = (props) => {
    // 
    const { t } = useTranslation()
    const [copied, setCopied] = useState(false);

    const renderPopover = (content) => (
        <Popover>
            <Popover.Body>{`${content}`}</Popover.Body>
        </Popover>
    );
    const copyToClipboard = async (text) => {
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Clipboard copy failed:", error);
            }
        } else {
            // Clipboard API is not supported, provide a fallback method
            try {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Fallback clipboard copy failed:", error);
            }
        }
    };


    return (
        <>
            {props.isLoading ? (
                <div className="tab-pane fade active show" id="nav-email" role="tabpanel" aria-labelledby="nav-banner-tab">
                    <h4 className="heading_style_1">
                        <Skeleton width="15%" />
                    </h4>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="banner_invite_img">
                                <Skeleton height="200px" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Skeleton width="30%" height={30} />
                            <Skeleton height={100} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="tab-pane fade active show" id="nav-banner" role="tabpanel" aria-labelledby="nav-banner-tab">
                    <h4 className="heading_style_1">Banner Invites</h4>
                    {props?.data?.map((item, key) => (
                        <>
                            <div key={key} className="row">
                                <div className="col-md-4">
                                    <div className="banner_invite_img">
                                        <img src={item.content} alt="Banner Image" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h6>{item.subject}</h6>
                                    <textarea className="banner_image_content_area" value={`<a href='${item.targetUrl}'></a>`} disabled />


                                    <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={renderPopover(copied ? "Copied" : "Copy")}>
                                        <span>
                                            <button
                                                onClick={() => copyToClipboard(`${item.targetUrl}`)}
                                                className="btn btn-primary">
                                                {t('copy')}
                                            </button>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <br />
                        </>
                    ))}
                </div>
            )}
        </>
    )
};

export default BannerInvites;